import { Animation, Vector3 } from "@babylonjs/core"

const cameraDefaultPos = new Vector3(-80, 25, 65)
const lockedTargetDefaultPos = new Vector3(23, -1, 20)

const animateObject = (
  scene,
  startPos,
  endPos,
  target,
  numOfFrames,
  onEnded
) => {
  const animation = new Animation(
    "myAnimation",
    "position",
    30,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  )

  const keys = []
  keys.push({
    frame: 0,
    value: startPos,
  })
  keys.push({
    frame: numOfFrames,
    value: endPos,
  })

  animation.setKeys(keys)
  target.animations = []
  target.animations.push(animation)
  target.animations.push()
  scene.beginAnimation(target, 0, numOfFrames, false, 1, onEnded)
}

let freelook
export const zoom = (camera, scene, mesh, onStart, onEnded) => {
  const itEnded = function () {
    freelook = true
  }

  onStart()

  const cameraDefaultPos = camera.position.clone()
  const lockedTarget = scene?.meshes.filter(el => el.name === "locked")[0]
  const lockedTargetDefaultPos = scene?.meshes.filter(
    el => el.name === "locked"
  )[0].position
  setTimeout(() => {
    animateObject(
      scene,
      cameraDefaultPos,
      new Vector3(
        mesh._position._x - 10,
        mesh._position._y,
        mesh._position._z - (window.innerWidth / window.innerHeight) * 3
      ),
      camera,
      100,
      onEnded
    )
    animateObject(
      scene,
      lockedTargetDefaultPos,
      { ...mesh.position, _z: mesh.position._z - 3.5, _y: -1 },
      lockedTarget,
      50
    )
  }, [600])
}
export const zoomOut = (camera, scene, previous, onStart, onEnded) => {
  onStart()
  const lockedTarget = scene?.meshes.filter(el => el.name === "locked")[0]

  animateObject(scene, camera.position.clone(), cameraDefaultPos, camera, 50)
  animateObject(
    scene,
    previous.position.clone(),
    lockedTargetDefaultPos,
    lockedTarget,
    50,
    onEnded
  )
}
