// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-about-jsx": () => import("./../../../src/pages/en/about.jsx" /* webpackChunkName: "component---src-pages-en-about-jsx" */),
  "component---src-pages-en-biografia-jsx": () => import("./../../../src/pages/en/biografia.jsx" /* webpackChunkName: "component---src-pages-en-biografia-jsx" */),
  "component---src-pages-en-dziela-jsx": () => import("./../../../src/pages/en/dziela.jsx" /* webpackChunkName: "component---src-pages-en-dziela-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-kongres-jsx": () => import("./../../../src/pages/en/kongres.jsx" /* webpackChunkName: "component---src-pages-en-kongres-jsx" */),
  "component---src-pages-en-kosmicznepytania-jsx": () => import("./../../../src/pages/en/kosmicznepytania.jsx" /* webpackChunkName: "component---src-pages-en-kosmicznepytania-jsx" */),
  "component---src-pages-en-lemwkosmosie-jsx": () => import("./../../../src/pages/en/lemwkosmosie.jsx" /* webpackChunkName: "component---src-pages-en-lemwkosmosie-jsx" */),
  "component---src-pages-en-solaris-jsx": () => import("./../../../src/pages/en/solaris.jsx" /* webpackChunkName: "component---src-pages-en-solaris-jsx" */),
  "component---src-pages-en-spojrzeniewprzyszlosc-jsx": () => import("./../../../src/pages/en/spojrzeniewprzyszlosc.jsx" /* webpackChunkName: "component---src-pages-en-spojrzeniewprzyszlosc-jsx" */),
  "component---src-pages-en-warsztatpisarski-jsx": () => import("./../../../src/pages/en/warsztatpisarski.jsx" /* webpackChunkName: "component---src-pages-en-warsztatpisarski-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pl-about-jsx": () => import("./../../../src/pages/pl/about.jsx" /* webpackChunkName: "component---src-pages-pl-about-jsx" */),
  "component---src-pages-pl-biografia-jsx": () => import("./../../../src/pages/pl/biografia.jsx" /* webpackChunkName: "component---src-pages-pl-biografia-jsx" */),
  "component---src-pages-pl-dziela-jsx": () => import("./../../../src/pages/pl/dziela.jsx" /* webpackChunkName: "component---src-pages-pl-dziela-jsx" */),
  "component---src-pages-pl-index-jsx": () => import("./../../../src/pages/pl/index.jsx" /* webpackChunkName: "component---src-pages-pl-index-jsx" */),
  "component---src-pages-pl-kongres-jsx": () => import("./../../../src/pages/pl/kongres.jsx" /* webpackChunkName: "component---src-pages-pl-kongres-jsx" */),
  "component---src-pages-pl-kosmicznepytania-jsx": () => import("./../../../src/pages/pl/kosmicznepytania.jsx" /* webpackChunkName: "component---src-pages-pl-kosmicznepytania-jsx" */),
  "component---src-pages-pl-lemwkosmosie-jsx": () => import("./../../../src/pages/pl/lemwkosmosie.jsx" /* webpackChunkName: "component---src-pages-pl-lemwkosmosie-jsx" */),
  "component---src-pages-pl-solaris-jsx": () => import("./../../../src/pages/pl/solaris.jsx" /* webpackChunkName: "component---src-pages-pl-solaris-jsx" */),
  "component---src-pages-pl-spojrzeniewprzyszlosc-jsx": () => import("./../../../src/pages/pl/spojrzeniewprzyszlosc.jsx" /* webpackChunkName: "component---src-pages-pl-spojrzeniewprzyszlosc-jsx" */),
  "component---src-pages-pl-warsztatpisarski-jsx": () => import("./../../../src/pages/pl/warsztatpisarski.jsx" /* webpackChunkName: "component---src-pages-pl-warsztatpisarski-jsx" */)
}

