import React from "react"
import Lottie from "react-lottie-player"

const getScaleByRatio = value => {
  const deviceRatio =
    typeof window !== "undefined" ? window.devicePixelRatio : 1
  return value / deviceRatio
}

export const PlanetTarget = ({ show, top, left, size = 300, animData }) => {
  const [currentFrame, setCurrentFrame] = React.useState(undefined)
  const getFixedSize = getScaleByRatio(size)

  return (
    <Lottie
      animationData={animData}
      play={
        (show && !currentFrame) ||
        (show && currentFrame < animData.op - 1) ||
        (!show && currentFrame > 0)
      }
      direction={show ? 1 : -1}
      onEnterFrame={evt => setCurrentFrame(Math.floor(evt.currentTime))}
      loop
      speed={show ? 1 : 2}
      style={{
        width: getFixedSize,
        height: getFixedSize,
        position: "fixed",
        top: getScaleByRatio(top),
        left: getScaleByRatio(left),
        transform: "translate3d(-70.3%, -57%, 0)",
        pointerEvents: "none",
      }}
    />
  )
}

export default PlanetTarget
