import React from "react"
import Box from "components/Box"
import { navigate } from "gatsby-link"
import navigation from "layouts/navigation"
import { LangButton } from "./styles"
import { getLangFromPath } from "utils"

const LanguageSelector = ({ planetSelected }) => {
  const languages = ["pl", "en"]

  const onLanguageSelect = e => {
    const dataLang = e.target.dataset.lang
    if (planetSelected) {
      navigate(`/${dataLang}/${navigation[planetSelected][dataLang].url}`)
    } else {
      navigate(`/${dataLang}`)
    }
  }

  return (
    <Box
      position="fixed"
      zIndex="100"
      top="4%"
      right="2%"
      display="flex"
      alignItems="center"
    >
      {languages.map(lang => (
        <LangButton
          active={lang === getLangFromPath()}
          key={lang}
          data-lang={lang}
          onClick={onLanguageSelect}
        >
          {lang}
        </LangButton>
      ))}
    </Box>
  )
}

export default LanguageSelector
