import Box from "components/Box"
import Typography from "components/Typography"
import React from "react"
import * as S from "./styles"
import { getLangFromPath } from "utils"
import navigation from "layouts/navigation"
import { Link } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"

const variants = {
  hidden: {
    transform: "translate3d(-50%, 0, 0)",
    opacity: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
  visible: {
    transform: "translate3d(0%, 0, 0)",
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
}

const MotionBox = motion(Box)

const LinkOrA = ({ planet }) => {
  if (navigation[planet][getLangFromPath()].externalUrl) {
    return (
      <a
        style={{ textDecoration: "none" }}
        href={navigation[planet][getLangFromPath()].externalUrl}
      >
        {navigation[planet][getLangFromPath()].label}
      </a>
    )
  } else {
    return (
      <Link
        to={navigation[planet][getLangFromPath()].url}
        style={{ textDecoration: "none" }}
      >
        {navigation[planet][getLangFromPath()].label}
      </Link>
    )
  }
}

export const Menu = ({ setTargeted, show, targeted, setPlanetSelected }) => {
  // if (!getLangFromPath()) return null
  return (
    <Box
      position="absolute"
      pl={7}
      ml={3}
      top={0}
      height="100vh"
      width="30%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      zIndex={3}
      style={{ pointerEvents: "none" }}
      overflow="hidden"
    >
      <Box>
        <AnimatePresence>
          {show && (
            <MotionBox
              mb={5}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <Typography
                variant="menuTitle"
                color="text.secondary"
                fontSize={20}
                letterSpacing={6.5}
                lineHeight={1}
                px={1}
              >
                STANISŁAW
              </Typography>
              <Typography
                variant="menuTitle"
                color="text.secondary"
                lineHeight={1}
              >
                LEM
              </Typography>
            </MotionBox>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {show && (
            <S.MenuList
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              {Object.keys(navigation).map(planet => (
                <S.MenuItem
                  key={
                    navigation[planet][getLangFromPath()].label +
                    getLangFromPath()
                  }
                  variants={variants}
                >
                  <Typography variant="menuItem" color="text.primary">
                    <span
                      onMouseOver={() => setTargeted(planet)}
                      onMouseLeave={() => setTargeted(undefined)}
                      onClick={() =>
                        setPlanetSelected ? setPlanetSelected(planet) : null
                      }
                    >
                      <LinkOrA planet={planet} />
                    </span>
                    <S.BackgroundBlock isVisible={targeted === planet} />
                  </Typography>
                </S.MenuItem>
              ))}
            </S.MenuList>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  )
}
Menu.displayName = "Menu"
export default Menu
