import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import {
  Engine,
  Scene,
  StandardMaterial,
  UniversalCamera,
  PointLight,
  Box,
  CubeTexture,
  GlowLayer,
} from "react-babylonjs"
import { Vector3, Color3 } from "@babylonjs/core"
import { Planet, CameraOperator } from "components/Planets"
import Menu from "components/Menu"
import PlanetTarget from "./PlanetTarget"
import Orbit from "./Orbit"
import NoiseOverlay from "components/Noise"
import PointLightAnim from "./PointLightAnim"
import { useCoords } from "../../store/CoordsContext"
import { anims } from "./lottie"

const SceneContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  canvas {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 992px) {
    display: none;
  }
`

const getLangFromPath = () => {
  if (typeof location !== "undefined") {
    const pathArray = location.pathname.split("/").filter(e => e)
    if (pathArray.length > 0) {
      return pathArray[0]
    } else {
      return undefined
    }
  }
}

export const PlanetScene = ({
  planetSelected,
  location,
  setPlanetSelected,
  lang,
  isLoaded,
  setLoaded,
  isSplashExited,
}) => {
  const highlightLayerRef = useRef(null)
  const [planetCoords, setPlanetCoords] = useState(null)
  const [targeted, setTargeted] = useState(undefined)
  const [isAnimating, setAnimating] = useState(false)

  const onHover = name => {
    setTargeted(name)
  }

  const [, setCoords] = useCoords()
  const isHomepage =
    location.pathname === `/${getLangFromPath()}` ||
    location.pathname === `/${getLangFromPath()}/`

  return (
    <SceneContainer style={{ opacity: isLoaded ? 1 : 0 }}>
      <Menu
        targeted={targeted}
        setTargeted={setTargeted}
        show={
          isSplashExited &&
          (location.pathname === "/pl/" ||
            location.pathname === "/en/" ||
            location.pathname === "/pl" ||
            location.pathname === "/en")
        }
      />
      <NoiseOverlay variant="light" />
      <PlanetTarget
        show={targeted === "solaris" && isHomepage && !isAnimating}
        top={planetCoords?.solaris?.y}
        left={planetCoords?.solaris?.x}
        size={220}
        animData={anims[getLangFromPath()]?.chapter2}
      />
      <PlanetTarget
        show={targeted === "works" && isHomepage && !isAnimating}
        top={planetCoords?.works?.y}
        left={planetCoords?.works?.x}
        size={200}
        animData={anims[getLangFromPath()]?.chapter3}
      />
      <PlanetTarget
        show={targeted === "bio" && isHomepage && !isAnimating}
        top={planetCoords?.bio?.y}
        left={planetCoords?.bio?.x}
        size={250}
        animData={anims[getLangFromPath()]?.chapter4}
      />
      <PlanetTarget
        show={targeted === "craft" && isHomepage && !isAnimating}
        top={planetCoords?.craft?.y}
        left={planetCoords?.craft?.x}
        size={400}
        animData={anims[getLangFromPath()]?.chapter5}
      />
      <PlanetTarget
        show={targeted === "qa" && isHomepage && !isAnimating}
        top={planetCoords?.qa?.y}
        left={planetCoords?.qa?.x}
        size={300}
        animData={anims[getLangFromPath()]?.chapter6}
      />
      <PlanetTarget
        show={targeted === "inventions" && isHomepage && !isAnimating}
        top={planetCoords?.inventions?.y}
        left={planetCoords?.inventions?.x}
        size={250}
        animData={anims[getLangFromPath()]?.chapter1}
      />
      <PlanetTarget
        show={targeted === "cleverHeads" && isHomepage && !isAnimating}
        top={planetCoords?.cleverHeads?.y}
        left={planetCoords?.cleverHeads?.x}
        size={200}
        animData={anims[getLangFromPath()]?.chapter7}
      />
      <PlanetTarget
        show={targeted === "space" && isHomepage && !isAnimating}
        top={planetCoords?.space?.y}
        left={planetCoords?.space?.x}
        size={200}
        animData={anims[getLangFromPath()]?.chapter8}
      />

      <Engine antialias adaptToDeviceRatio canvasId="babylonJS">
        <Scene
          ambientColor={new Color3(1, 1, 1)}

          // onAfterRenderObservable={() => ////console.log("test")}
        >
          <CameraOperator />
          <UniversalCamera name="camera1" position={new Vector3(-80, 25, 65)} />
          <PointLight
            name="sunlight"
            intensity={1.5}
            position={new Vector3(0, 0, 0)}
          />
          <Orbit radius={21} planetSelected={planetSelected} />
          <Orbit radius={27} planetSelected={planetSelected} />
          <Orbit radius={33} planetSelected={planetSelected} />
          <Orbit radius={39} planetSelected={planetSelected} />
          <Orbit radius={45} planetSelected={planetSelected} />
          <Orbit radius={51} planetSelected={planetSelected} />
          <Orbit radius={57} planetSelected={planetSelected} />
          <Orbit radius={63} planetSelected={planetSelected} />
          <GlowLayer
            name="glow"
            mainTextureFixedSize={1024}
            blurKernelSize={32}
            isEnabled
            intensity={1.5}
          />
          <Planet
            name="locked"
            position={new Vector3(23, -1, 20)}
            diameter={0}
            segments={0}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            unselectable
            location={location}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          <Planet
            name="sun"
            position={new Vector3(0, 0, 0)}
            diameter={20}
            segments={32}
            emissiveTexture="/sun2.jpg"
            emissive={new Color3(1, 1, 1)}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            unselectable
            location={location}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          <Planet
            name="bio"
            position={new Vector3(0, 0, 39)}
            diameter={3}
            bumpLevel={1}
            segments={256}
            bumpTexture={"/biografia_bump.jpg"}
            diffuseTexture={"/biografia_texture.jpg"}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            onHover={onHover}
            location={location}
            lang={lang}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          <Planet
            name="solaris"
            position={new Vector3(-8, 0, -26)}
            diameter={3}
            bumpLevel={1}
            segments={256}
            bumpTexture={"/solaris_bump.jpg"}
            diffuseTexture={"/solaris_texture.jpg"}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            onHover={onHover}
            location={location}
            lang={lang}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            ambientColor={new Color3(0.12, 0.12, 0.12)}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          <Planet
            name="works"
            position={new Vector3(33, 0, 3)}
            diameter={3}
            bumpLevel={1}
            segments={32}
            bumpTexture={"/dziela_bump.jpg"}
            diffuseTexture={"/dziela_tekstura.jpg"}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            onHover={onHover}
            location={location}
            lang={lang}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            ambientColor={new Color3(0.12, 0.12, 0.12)}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          <Planet
            name="qa"
            position={new Vector3(-24, 0, 45)}
            diameter={3}
            bumpLevel={1}
            segments={32}
            bumpTexture={"/trudne_odpowiedzi_bump.jpg"}
            diffuseTexture={"/trudne_odpowiedzi_texture.jpg"}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            onHover={onHover}
            location={location}
            lang={lang}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            ambientColor={new Color3(0.12, 0.12, 0.12)}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          <Planet
            name="craft"
            position={new Vector3(-42.5, 0, 15)}
            diameter={3}
            bumpLevel={1}
            segments={32}
            bumpTexture={"/warsztat_bump.jpg"}
            diffuseTexture={"/warsztat_texture.jpg"}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            onHover={onHover}
            location={location}
            lang={lang}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            ambientColor={new Color3(0.12, 0.12, 0.12)}
            backlight={new Vector3(-45.5, 2, 7)}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          {/* TODO: enable when zoomed on this planet */}
          <PointLightAnim planetSelected={planetSelected} />
          <Planet
            name="inventions"
            position={new Vector3(-3, 0, 21)}
            diameter={3}
            bumpLevel={1}
            segments={32}
            bumpTexture={"/wynalazki_bump.jpg"}
            diffuseTexture={"/wynalazki_texture.jpg"}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            onHover={onHover}
            location={location}
            lang={lang}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            ambientColor={new Color3(0.12, 0.12, 0.12)}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          <Planet
            name="cleverHeads"
            position={new Vector3(30, 0, 49)}
            diameter={3}
            bumpLevel={1}
            segments={128}
            bumpTexture={"/madre_glowy_bump.jpg"}
            diffuseTexture={"/madre_glowy_texture.jpg"}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            onHover={onHover}
            location={location}
            lang={lang}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            ambientColor={new Color3(0.12, 0.12, 0.12)}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          <Planet
            name="space"
            position={new Vector3(65, 0, 10)}
            diameter={3}
            bumpLevel={1}
            segments={128}
            bumpTexture={"/wkosmosie_bump.jpg"}
            diffuseTexture={"/wkosmosie_texture.jpg"}
            planetSelected={planetSelected}
            setPlanetSelected={setPlanetSelected}
            highlightRef={highlightLayerRef}
            onHover={onHover}
            location={location}
            lang={lang}
            setPlanetCoords={setPlanetCoords}
            planetCoords={planetCoords}
            targeted={targeted}
            ambientColor={new Color3(0.12, 0.12, 0.12)}
            setContextCoords={setCoords}
            isAnimating={isAnimating}
            setAnimating={setAnimating}
          />
          <Box size={10000} name="skybox">
            <StandardMaterial backFaceCulling={false} disableLighting={false}>
              <CubeTexture
                assignTo="reflectionTexture"
                rootUrl="/textures/skybox"
                coordinatesMode={5}
                emissiveTexture={new Color3(1, 1, 1)}
                onLoad={() => setLoaded(true)}
              />
            </StandardMaterial>
          </Box>
        </Scene>
      </Engine>
    </SceneContainer>
  )
}
