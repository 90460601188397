import React, { useReducer } from "react"

type PlayerState = {
  title?: string
  url: string
  playing: boolean
  volume: number
  duration: number | null
  progress: number
  description?: string | null
  updateProgress: { amount: number; type: "fraction" | "seconds" | undefined }
}

type PlayerAction = {
  type: string
  payload: any
}

const defaultPlayerState: PlayerState = {
  title: "",
  description: "",
  url: "",
  playing: false,
  volume: 1,
  duration: 0,
  progress: 0,
  updateProgress: { amount: 0, type: "fraction" },
}

export const PlayerContext = React.createContext<{
  player: PlayerState
  setPlayerContext: React.Dispatch<any>
}>({
  player: defaultPlayerState,
  setPlayerContext: () => null,
})

type Props = {
  children: React.ReactNode
}

const PlayerContextWrapper: React.FC<Props> = ({ children }): JSX.Element => {
  const [player, setPlayerContext] = useReducer(
    (state: PlayerState, action: PlayerAction) => {
      if (!action.payload.progress) {
      }
      if (action.type === "SET_PLAYER") {
        return { ...state, ...action.payload }
      }
      return state
    },
    defaultPlayerState
  )

  return (
    <PlayerContext.Provider value={{ player, setPlayerContext }}>
      {children}
    </PlayerContext.Provider>
  )
}

export default PlayerContextWrapper
