import parse from "html-react-parser"
import { useLocation } from "@reach/router"

export const useContent = (query: string | void) => {
  let tagObj
  const resolve = (tag: string) => {
    try {
      tagObj = tag.toLowerCase().replace(/_/g, ".")
      if (typeof tag !== "string") return undefined
    } catch {
      return undefined
    }
    try {
      if (
        query &&
        eval(`query.${tagObj}`) &&
        eval(`query.${tagObj}.content`) &&
        eval(`query.${tagObj}.content`).trim().length > 0
      )
        return parse(eval(`query.${tagObj}.content`))
    } catch {
      return tag.toUpperCase()
    }
    return tag.toUpperCase()
  }
  return { resolve }
}

export const getLangFromPath = () : string => {
  const { pathname } = useLocation()
  const diffLanguages = ["en"]
  let result = "pl"

  pathname &&
    pathname.split("/").forEach(el => {
      const lang = diffLanguages.find(language => language === el)
      if (lang) result = lang.toLowerCase()
    })

  return result ? result : 'pl';
}

