import styled from "styled-components"
import { motion } from "framer-motion"

export const ContentWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const WrapperLowSpecBg = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/lowres/tlo_low.jpg");
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 768px) {
    display: none;
  }
`

export const WrapperPlanet = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  top: ${({ top }) => `${top}`};
  left: ${({ left }) => `${left}`};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 25vw;
  height: 25vw;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  left: 10%;
`

export const WrapperLowRes = styled(WrapperLowSpecBg)`
  background-image: url("/lowres/tlo_rozdzial_low.jpg");

  @media (max-width: 768px) {
    display: none;
  }
`

export const MobileWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`
