import React, { useState, useEffect, useRef, useCallback } from "react"
import {
  useBeforeRender,
  useScene,
  useHover,
  useClick,
  Sphere,
  StandardMaterial,
  Texture,
  PointLight,
  useEngine,
} from "react-babylonjs"
import * as BABYLON from "@babylonjs/core"
import { zoom, zoomOut } from "./camera"
import { navigate } from "gatsby"
import { getLangFromPath } from "utils"
import navigation from "layouts/navigation"
import _ from "lodash"

const deviceRatio = typeof window !== "undefined" && window.devicePixelRatio
const getScaleByRatio = value => {
  return value / deviceRatio
}

export const Planet = props => {
  const {
    emissiveTexture,
    diffuseTexture,
    bumpLevel,
    bumpTexture,
    emissive,
    diameter,
    location,
    planetSelected,
    setPlanetSelected,
    name,
    onHover,
    unselectable,
    segments,
    lang,
    setPlanetCoords,
    ambientColor,
    targeted,
    setContextCoords,
    isAnimating,
    setAnimating,
  } = props

  const transformNodeRef = useRef(null)
  const planetRef = useRef(null)
  const scene = useScene()
  const engine = useEngine()
  const [isClicked, setClicked] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [mesh, setMesh] = useState(null)
  const prev = useRef()
  const [alphaValue, setAlphaValue] = useState(1)
  const timeoutRef = useRef()

  useEffect(() => {
    const updateCoords = () => {
      if (planetSelected === name)
        setContextCoords({
          x: getScaleByRatio(calculatePlanetCenter()._x),
          y: getScaleByRatio(calculatePlanetCenter()._y),
        })
    }
    window.addEventListener("resize", updateCoords)
    return () => window.removeEventListener("resize", updateCoords)
  }, [])

  useEffect(() => {
    let planetUrl = navigation[planetSelected]?.[getLangFromPath()]?.url
    if (!location.pathname.includes(planetUrl)) {
      prev.current = false
    }
  }, [location.pathname])
  // const [coords, setCoords] = React.useContext(CenterContext)
  useEffect(() => {
    if (
      (location?.pathname === `/${lang}/` ||
        location?.pathname === `/${lang}`) &&
      planetSelected &&
      planetSelected === name
    ) {
      setPlanetSelected(null)
      setContextCoords(null)

      zoomOut(
        scene.activeCamera,
        scene,
        planetRef.current,
        () => setAnimating(true),
        () => {
          setAnimating(false)
        }
      )
    } else if (!planetSelected && location?.pathName !== `/${lang}`) {
      setPlanetSelected(
        _.findKey(
          navigation,
          planet =>
            planet[getLangFromPath()]?.url ===
            location?.pathname.substr(4).replace("/", "")
        )
      )
    } else if (planetSelected === name) {
      zoom(
        scene.activeCamera,
        scene,
        planetRef.current,
        () => setAnimating(true),
        () => {
          setContextCoords({
            x: getScaleByRatio(calculatePlanetCenter()._x),
            y: getScaleByRatio(calculatePlanetCenter()._y),
          })
          setAnimating(false)
        }
      )
    }
  }, [location, planetSelected])

  useEffect(() => {
    if (planetRef?.current?._material?.diffuseTexture) {
      planetRef.current._material.diffuseTexture.uScale = 1
      planetRef.current._material.diffuseTexture.vScale = 1
    }
  }, [scene, planetRef, bumpLevel])

  if (!unselectable) useClick(() => setClicked(prev => !prev), planetRef)

  useEffect(() => {
    if (!unselectable) {
      if (isClicked !== prev.current) {
        prev.current = isClicked
        if (isClicked) {
          navigate(
            `/${getLangFromPath()}/${navigation[name][getLangFromPath()].url}`
          )
        }

        if (!isClicked) {
          navigate("/")
        }
      }
    }
  }, [isClicked])

  if (!unselectable)
    useHover(
      () => setHovered(true),
      () => setHovered(false),
      planetRef
    )

  useEffect(() => {
    if (hovered && !unselectable) {
      onHover(props.name)
    }
    if (!hovered && onHover) {
      onHover(undefined)
    }
  }, [hovered])

  useEffect(() => {
    const coords = calculatePlanetCenter()
    setPlanetCoords(prevState => ({
      ...prevState,
      [planetRef.current.name]: {
        x: coords._x,
        y: coords._y,
      },
    }))
  }, [targeted])

  const calculatePlanetCenter = useCallback(() => {
    return BABYLON.Vector3.Project(
      planetRef.current.getBoundingInfo().boundingSphere.centerWorld,
      BABYLON.Matrix.IdentityReadOnly,
      scene.getTransformMatrix(),
      scene.activeCamera.viewport.toGlobal(
        engine.getRenderWidth(),
        engine.getRenderHeight()
      )
    )
  }, [planetRef.current, scene, engine])

  const rpm = 1
  useBeforeRender(scene => {
    if (planetRef.current) {
      // Delta time smoothes the animation.
      var deltaTimeInMillis = scene.getEngine().getDeltaTime()
      planetRef.current.rotation.y +=
        (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000)
    }
  })

  useEffect(() => {
    if (planetSelected !== props.name && planetSelected) {
      let x = alphaValue
      let time = planetSelected === "about" ? 50 : 200

      const startTimeout = () => {
        timeoutRef.current = setTimeout(startTimeout, time)
        x -= 0.03
        time -= 6
        setAlphaValue(x)
        if (time > 0) timeoutRef.current
      }
      startTimeout()
    } else {
      setAlphaValue(1)
    }
    return () => clearTimeout(timeoutRef.current)
  }, [planetSelected, props.name])

  // useEffect(() => {
  //     planetRef.current.position.x = position.x
  //     planetRef.current.position.y = position.y
  //     planetRef.current.position.z = position.z

  // }, [])
  return (
    <>
      <Sphere
        ref={planetRef}
        name={props.name}
        diameter={diameter}
        position={props.position}
        segments={segments}
      >
        <StandardMaterial
          name={`${props.name}-mat`}
          specularColor={emissive ? null : new BABYLON.Color3(0.15, 0.15, 0.15)}
          emissiveColor={emissive || null}
          ambientColor={ambientColor}
          alpha={alphaValue}
        >
          {emissiveTexture ? (
            <Texture assignTo={"emissiveTexture"} url={emissiveTexture} />
          ) : (
            <>
              <Texture assignTo={"diffuseTexture"} url={diffuseTexture} />
              <Texture
                assignTo={"bumpTexture"}
                url={bumpTexture}
                level={bumpLevel}
              />
            </>
          )}
        </StandardMaterial>
      </Sphere>
      {props.backlight && planetSelected === props.name && (
        <PointLight position={props.backlight} intensity={0.4} />
      )}
    </>
  )
}
