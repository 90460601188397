
export const getLangFromPath = () : string => {
  if (typeof window !== "undefined") {
    return window.location.pathname.split("/")[1]
  }
  return "pl"
}

export const isMobile = typeof window !== "undefined" && window.innerWidth < 768
export const isDesktop =
  typeof window !== "undefined" && window.innerWidth > 992

export function circlePath(
  cx: number,
  cy: number,
  r: number,
  deg: number,
  swap = 0
) {
  const theta = (deg * Math.PI) / 180,
    dx = r * Math.cos(theta),
    dy = -r * Math.sin(theta)
  return (
    "M " +
    cx +
    " " +
    cy +
    "m " +
    dx +
    "," +
    dy +
    "a " +
    r +
    "," +
    r +
    " 0 1," +
    swap +
    " " +
    -2 * dx +
    "," +
    -2 * dy +
    "a " +
    r +
    "," +
    r +
    " 0 1," +
    swap +
    " " +
    2 * dx +
    "," +
    2 * dy
  )
}
// TODO: Rozalia
export const calculateTextOnCircle = (
  length: number,
  number: number
): { x: number; y: number } => {
  switch (true) {
    case length === 9 && number === 0:
      return {
        x: 0,
        y: -35,
      }
    case length === 9 && number === 1:
      return {
        x: 10,
        y: -30,
      }
    case length === 9 && number === 2:
      return {
        x: 20,
        y: -20,
      }
    case length === 9 && number === 3:
      return {
        x: 30,
        y: -10,
      }
    case length === 9 && number === 4:
      return {
        x: 30,
        y: -10,
      }
    case length === 9 && number === 5:
      return {
        x: 25,
        y: -7,
      }
    case length === 9 && number === 6:
      return {
        x: 20,
        y: -15,
      }
    case length === 9 && number === 7:
      return {
        x: 20,
        y: -5,
      }
    case length === 9 && number === 8:
      return {
        x: 20,
        y: -5,
      }
    case length === 11 && number === 0:
      return {
        x: -93,
        y: -50,
      }
    case length === 11 && number === 1:
      return {
        x: -13,
        y: -72,
      }
    case length === 11 && number === 2:
      return {
        x: 25,
        y: -30,
      }
    case length === 11 && number === 3:
      return {
        x: 25,
        y: -10,
      }
    case length === 11 && number === 4:
      return {
        x: 25,
        y: -7,
      }
    case length === 11 && number === 5:
      return {
        x: 20,
        y: -8,
      }
    case length === 11 && number === 6:
      return {
        x: 20,
        y: -5,
      }
    case length === 11 && number === 7:
      return {
        x: 18,
        y: -5,
      }
    case length === 11 && number === 8:
      return {
        x: 18,
        y: -5,
      }
    case length === 11 && number === 9:
      return {
        x: 15,
        y: -5,
      }
    case length === 11 && number === 10:
      return {
        x: 15,
        y: -5,
      }
    case length === 5 && number === 0:
      return {
        x: 5,
        y: -30,
      }
    case length === 5 && number === 1:
      return {
        x: 22,
        y: -20,
      }
    case length === 5 && number === 2:
      return {
        x: 25,
        y: -5,
      }
    case length === 5 && number === 3:
      return {
        x: 20,
        y: -10,
      }
    case length === 5 && number === 4:
      return {
        x: 15,
        y: 0,
      }
    case length === 7 && number === 0:
      return {
        x: -10,
        y: -50,
      }
    case length === 7 && number === 1:
      return {
        x: 20,
        y: -40,
      }
    case length === 7 && number === 2:
      return {
        x: 23,
        y: -25,
      }
    case length === 7 && number === 3:
      return {
        x: 20,
        y: -8,
      }
    case length === 7 && number === 4:
      return {
        x: 20,
        y: -5,
      }
    case length === 7 && number === 5:
      return {
        x: 17,
        y: -2,
      }
    case length === 7 && number === 6:
      return {
        x: 15,
        y: -5,
      }
    case length === 2 && number === 0:
      return {
        x: 15,
        y: -32,
      }
    case length === 2 && number === 1:
      return {
        x: 28,
        y: 10,
      }
  }

  return {
    x: 0,
    y: 0,
  }
}

export function fancyTimeFormat(duration: number) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600)
  var mins = ~~((duration % 3600) / 60)
  var secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ""

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "")
  ret += "" + secs
  return ret
}
