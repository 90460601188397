import React from "react"
import { Noise, NoiseGrey, Tekstura } from "./styles"

export const NoiseOverlay = ({ variant }) => {
  if (!variant || variant === "dark") return <Noise />
  if (variant === "light") return <NoiseGrey />
  if (variant === "texture") return <Tekstura />
  return null
}

export default NoiseOverlay
