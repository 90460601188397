import ch1pl from "./chapter1pl.json"
import ch2pl from "./chapter2pl.json"
import ch3pl from "./chapter3pl.json"
import ch4pl from "./chapter4pl.json"
import ch5pl from "./chapter5pl.json"
import ch6pl from "./chapter6pl.json"
import ch7pl from "./chapter7pl.json"
import ch8pl from "./chapter8pl.json"
import ch1en from "./chapter1en.json"
import ch3en from "./chapter3en.json"
import ch4en from "./chapter4en.json"
import ch5en from "./chapter5en.json"
import ch6en from "./chapter6en.json"
import ch7en from "./chapter7en.json"
import ch8en from "./chapter8en.json"

export const anims = {
  pl: {
    chapter1: ch1pl,
    chapter2: ch2pl,
    chapter3: ch3pl,
    chapter4: ch4pl,
    chapter5: ch5pl,
    chapter6: ch6pl,
    chapter7: ch7pl,
    chapter8: ch8pl,
  },
  en: {
    chapter1: ch1en,
    chapter2: ch2pl,
    chapter3: ch3en,
    chapter4: ch4en,
    chapter5: ch5en,
    chapter6: ch6en,
    chapter7: ch7en,
    chapter8: ch8en,
  },
}
