/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useContext } from "react"
import "./reset.css"
import "./global.css"
import { getLangFromPath } from "utils"
import { PlanetScene } from "components/Planets"
import { ThemeProvider } from "styled-components"
import { theme } from "UI/theme"
import { CoordsProvider } from "../store/CoordsContext"
import {
  ContentWrapper,
  WrapperLowSpecBg,
  WrapperPlanet,
  WrapperLowRes,
  MobileWrapper,
} from "./styles"
import LanguageSelector from "components/LanguageSelector/LanguageSelector"
import { AnimatePresence, motion } from "framer-motion"
import SplashScreen from "components/SplashScreen"
import PlayerContextWrapper from "../store/PlayerContext"
import Box from "components/Box"
import SEO from "../components/seo"
import { navigate } from "gatsby"
import { getGPUTier } from "detect-gpu"
import Menu from "components/Menu"
import navigation from "./navigation"
import FullSpecContextWrapper, {
  FullSpecContext,
} from "../store/FullSpecContext"

// const Skybox = props => {}

export const getPlanetFromPath = () => {
  if (typeof window != "undefined") {
    return window.location.pathname.split("/").filter(a => a.length > 0)[1]
  }
  return ""
}

export const getPlanetId = () => {
  const planetUrl = getPlanetFromPath()
  const lang = getLangFromPath()
  let result = ""
  for (let planet in navigation) {
    if (navigation?.[planet]?.[lang]?.url === planetUrl) result = planet
  }
  return result
}

const planets = {
  earth: {
    name: "Ziemia",
    description:
      "Duis laborum dolor cillum esse nulla et aliqua irure commodo duis voluptate cupidatat anim consectetur. ",
    link: "earth",
  },
  mars: {
    name: "Mars",
    description:
      "Exercitation magna occaecat nulla in. Esse occaecat sit officia enim ullamco deserunt. Sint velit officia fugiat ad adipisicing veniam.",
    link: "mars",
  },
  venus: {
    name: "Wenus",
    description:
      "Ad enim nulla duis in. Dolor tempor irure incididunt amet ad commodo ut eiusmod labore elit. ",
    link: "venus",
  },
} // FIX: to chyba trzeba usunąć

const IndexPage = ({ children, location }) => {
  const [planetSelected, setPlanetSelected] = useState(null)
  const [data, setData] = useState(null)
  const [isSplash, setSplash] = useState(false)
  const [isLoaded, setLoaded] = useState(false)
  const [isSplashExited, setSplashExited] = useState(false)
  const fullSpecContext = useContext(FullSpecContext)

  useEffect(() => {
    if (planetSelected) {
      setData(planets[planetSelected]) // Tutaj korzystamy z fejkowych danych
    } else {
      setData(null)
    }
  }, [planetSelected])

  getPlanetId()
  const isHomepage = () => {
    return (
      location.pathname === "/pl/" ||
      location.pathname === "/en/" ||
      location.pathname === "/pl" ||
      location.pathname === "/en" ||
      location.pathname === "/"
    )
  }

  useEffect(() => {
    if (typeof window !== "undefined" && location.pathname === "/") {
      if (location.host === "galaktykalema.pl") {
        navigate(`/pl/`)
        setSplash(true)
      } else if (location.host === "lemgalaxy.com") {
        navigate(`/en/`)
        setSplash(true)
      } else {
        navigate(`/pl/`)
        setSplash(true)
      }
    } else {
      if (isHomepage()) setSplash(true)
    }
  }, [])

  useEffect(() => {
    if (isHomepage() && !isSplash) {
      setSplashExited(true)
      setPlanetSelected(null)
    }
    if (!isHomepage()) {
      setSplashExited(true)
    }
  }, [location.pathname])

  const lang = getLangFromPath()

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768

  useEffect(() => {
    const detectLowSpec = async () => {
      const gpuTier = await getGPUTier()
      const isM1 = gpuTier.gpu === "apple m1 (Apple M1)"
      //console.log("Spec: ", gpuTier, fullSpecContext)
      if (gpuTier.tier > 1 || isM1) {
        // setLowSpecPC(false)
        fullSpecContext.setFullSpec(true)
      } else {
        // setLowSpecPC(true)
        fullSpecContext.setFullSpec(false)
        setLoaded(true)
      }
    }
    if (!isMobile) {
      detectLowSpec()
    }
  }, [])

  return (
    <>
      <SEO
        lang={"pl"}
        title={"Galaktyka Lema"}
        image={"/lem.png"}
        description="Jeden z najbardziej niezwykłych i oryginalnych umysłów XX wieku sam w sobie był istnym wszechświatem pojęć, koncepcji i doświadczeń. Od 60 lat cząstka Lema – jego głos – dzięki Polskiemu Radiu wędruje z prędkością świata przez odmęty kosmosu. Wyobraź sobie, że wędrujesz razem z nim. Wybierz się w niezwykłą podróż po Galaktyce Lema. "
      ></SEO>
      <PlayerContextWrapper>
        <CoordsProvider>
          <ThemeProvider theme={theme}>
            {!isMobile && (
              <>
                {!fullSpecContext.fullSpec ? (
                  <>
                    {isHomepage() ? (
                      <WrapperLowSpecBg>
                        <Menu
                          setTargeted={() => null}
                          setPlanetSelected={setPlanetSelected}
                          show={
                            isSplashExited &&
                            (location.pathname === "/pl/" ||
                              location.pathname === "/en/" ||
                              location.pathname === "/pl" ||
                              location.pathname === "/en")
                          }
                        />
                      </WrapperLowSpecBg>
                    ) : (
                      <WrapperLowRes>
                        <WrapperPlanet
                        // src={`/lowres/${
                        //   navigation[getPlanetId()]?.lowSpecPlanet || ""
                        // }`}
                        // width={
                        //   planetBorder?.current?.getBoundingClientRect()
                        //     .width - 40
                        // }
                        // height={
                        //   planetBorder?.current?.getBoundingClientRect()
                        //     .height - 40
                        // }
                        // top={
                        //   planetBorder?.current?.getBoundingClientRect().top +
                        //   20
                        // }
                        // left={
                        //   planetBorder?.current?.getBoundingClientRect()
                        //     .left + 20
                        // }
                        />
                      </WrapperLowRes>
                    )}
                  </>
                ) : (
                  <>
                    <PlanetScene
                      planetSelected={planetSelected}
                      setPlanetSelected={setPlanetSelected}
                      location={location}
                      lang={lang}
                      isLoaded={isLoaded}
                      setLoaded={setLoaded}
                      isSplashExited={isSplashExited}
                    />
                    {children}
                  </>
                )}

                <LanguageSelector planetSelected={planetSelected} />
              </>
            )}
            {isMobile && isSplashExited && (
              <MobileWrapper>{children}</MobileWrapper>
            )}
            <Box position="absolute" zIndex="10" top={0}>
              {isHomepage() && (
                <AnimatePresence onExitComplete={() => setSplashExited(true)}>
                  {isSplash && (
                    <motion.div
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 2 }}
                    >
                      <SplashScreen isLoaded={isLoaded} setSplash={setSplash} />
                    </motion.div>
                  )}
                </AnimatePresence>
              )}
            </Box>
            <AnimatePresence>
              {(planetSelected && fullSpecContext.fullSpec && !isMobile) ||
                (!fullSpecContext.fullSpec && !isHomepage() && !isMobile && (
                  <ContentWrapper
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: {
                        delay: !fullSpecContext.fullSpec ? 0 : 4,
                        duration: 1.8,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      transition: {
                        delay: !fullSpecContext.fullSpec ? 0 : 4,
                        duration: !fullSpecContext.fullSpec ? 0 : 1.8,
                      },
                    }}
                  >
                    {children}
                  </ContentWrapper>
                ))}
            </AnimatePresence>
          </ThemeProvider>
        </CoordsProvider>
      </PlayerContextWrapper>
    </>
  )
}

const WithFullSpecContext = ({ children, location }) => {
  return (
    <FullSpecContextWrapper>
      <IndexPage location={location}>{children}</IndexPage>
    </FullSpecContextWrapper>
  )
}

export default WithFullSpecContext
