import styled, { css } from "styled-components"
import { theme } from "UI/theme"

export const LangButton = styled.button`
  cursor: pointer;
  border: 1px solid ${theme.palette.neutral.light};
  background: transparent;
  border-radius: 50%;
  min-width: ${theme.space[4]}px;
  min-height: ${theme.space[4]}px;
  color: ${theme.palette.neutral.light};
  ${theme.textStyles.langButton}
  margin: 0 ${theme.space[1]}px;
  padding: ${theme.space[1]}px;
  transition: 0.3s all;

  ${({ active }) =>
    active
      ? css`
          border: 1px dashed ${theme.palette.neutral.light};
          background: ${theme.palette.neutral.light} content-box;

          color: ${theme.palette.text.dark};
          min-width: ${theme.space[4] + theme.space[2]}px;
          min-height: ${theme.space[4] + theme.space[2]}px;
        `
      : css`
          &:hover {
            background: ${theme.palette.neutral.light};
            color: ${theme.palette.text.dark};
          }
        `};
`
