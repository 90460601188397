export default {
  inventions: {
    mobilePlanet: "wynalazki_mobile.png",
    lowSpecPlanet: "wynalazki.png",
    pl: {
      label: "W przyszłość",
      url: "spojrzeniewprzyszlosc",
    },
    en: {
      label: "Into the future",
      url: "spojrzeniewprzyszlosc",
    },
  },
  solaris: {
    mobilePlanet: "solaris_mobile.png",
    lowSpecPlanet: "solaris.png",
    pl: {
      label: "Solaris",
      url: "solaris",
    },
    en: {
      label: "Solaris",
      url: "solaris",
    },
  },
  works: {
    mobilePlanet: "dziela_mobile.png",
    lowSpecPlanet: "dziela.png",
    pl: {
      label: "Dzieła",
      url: "dziela",
    },
    en: {
      label: "Works",
      url: "dziela",
    },
  },
  bio: {
    mobilePlanet: "biografia_mobile.png",
    lowSpecPlanet: "biografia.png",
    pl: {
      label: "Biografia",
      url: "biografia",
    },
    en: {
      label: "Biography",
      url: "biografia",
    },
  },
  craft: {
    mobilePlanet: "warsztat_mobile.png",
    lowSpecPlanet: "warsztat.png",
    pl: {
      label: "Warsztat pisarski",
      url: "warsztatpisarski",
    },
    en: {
      label: "Workshop",
      url: "warsztatpisarski",
    },
  },
  qa: {
    mobilePlanet: "trudne_odpowiedzi_mobile.png",
    lowSpecPlanet: "trudne_odpowiedzi.png",
    pl: {
      label: "Wielkie pytania",
      url: "kosmicznepytania",
    },
    en: {
      label: "Great questions",
      url: "kosmicznepytania",
    },
  },
  cleverHeads: {
    mobilePlanet: "madre_glowy_mobile.png",
    lowSpecPlanet: "madre_glowy.png",
    pl: {
      label: "Kongres",
      url: "kongres",
    },
    en: {
      label: "Congress",
      url: "kongres",
    },
  },
  space: {
    mobilePlanet: "lem_w_kosmosie_mobile.png",
    lowSpecPlanet: "lem_w_kosmosie.png",
    pl: {
      label: "Lem w kosmosie",
      url: "lemwkosmosie",
    },
    en: {
      label: "Lem in space",
      url: "lemwkosmosie",
    },
  },
  game: {
    mobilePlanet: "",
    pl: {
      label: "Gra",
      externalUrl: "https://lemgra.polskieradio.pl/",
    },
    en: {
      label: "Game",
      externalUrl: "https://lemgra.polskieradio.pl/",
    },
  },
  about: {
    mobilePlanet: "",
    pl: {
      label: "O projekcie",
      url: "about",
    },
    en: {
      label: "About",
      url: "about",
    },
  },
}
