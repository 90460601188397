import React, { useContext, useState } from "react"

const CoordsContext = React.createContext()

export const CoordsProvider = ({ children }) => {
  const [coords, setCoords] = useState(null)

  return (
    <CoordsContext.Provider value={[coords, setCoords]}>
      {children}
    </CoordsContext.Provider>
  )
}

export const useCoords = () => useContext(CoordsContext)
