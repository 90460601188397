import React, { useEffect, useRef } from "react"
import { DashedLines, useScene } from "react-babylonjs"
import { Vector3 } from "@babylonjs/core"
import { useTransform, useMotionValue } from "framer-motion"
import { useState } from "react"

const circlePoints = radius => {
  const points = []
  for (let i = 0; i < 200; i++) {
    let x = radius * Math.cos((2 * Math.PI * i) / 200)
    let z = radius * Math.sin((2 * Math.PI * i) / 200)
    let y = 0
    points.push(new Vector3(x, y, z))
  }
  return points
}

export const Orbit = ({ radius, planetSelected }) => {
  const thisRef = useRef()
  const [alphaValue, setAlphaValue] = useState(0.15)
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (planetSelected) {
      let x = alphaValue
      let time = planetSelected === "about" ? 50 : 1000

      const startTimeout = () => {
        timeoutRef.current = setTimeout(startTimeout, time)
        x -= 0.03
        time -= 150
        setAlphaValue(x)
        if (time > 0) timeoutRef.current
      }

      startTimeout()
    } else {
      setAlphaValue(0.15)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [planetSelected])

  return (
    <DashedLines
      points={circlePoints(radius)}
      ref={thisRef}
      gapSize={5}
      dashSize={5}
      alpha={alphaValue}
    />
  )
}

export default Orbit
