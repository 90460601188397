import React, { useEffect, useState } from "react"

export const FullSpecContext = React.createContext<{
  fullSpec: boolean
  setFullSpec: React.Dispatch<boolean>
}>({
  fullSpec: false,
  setFullSpec: (arg: boolean) => arg,
})

type Props = {
  children: React.ReactNode
}

const FullSpecContextWrapper: React.FC<Props> = ({ children }): JSX.Element => {
  const [fullSpec, setFullSpec] = useState(false)

  useEffect(() => {
    //console.log("XXX", fullSpec)
  }, [fullSpec])

  return (
    <FullSpecContext.Provider value={{ fullSpec, setFullSpec }}>
      {children}
    </FullSpecContext.Provider>
  )
}

export default FullSpecContextWrapper
