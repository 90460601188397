import React, { useEffect } from "react"
import { useScene } from "react-babylonjs"
import { Vector3 } from "@babylonjs/core"

export const CameraOperator = ({ sceneRef }) => {
  const scene = useScene()
  const camera = scene.activeCamera
  const lockedTarget = scene.getMeshByName("locked")

  useEffect(() => {
    if (lockedTarget) {
      camera.fov = 0.65
      camera.lockedTarget = lockedTarget
      camera._upVector = new Vector3(0.3, 1.5, -0.5)
    }
  }, [lockedTarget])
  return <></>
}
