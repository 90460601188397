import React, { useEffect, useState } from "react"
import { Vector3 } from "@babylonjs/core"
import { PointLight } from "react-babylonjs"

export const PointLightAnim = ({ planetSelected }) => {
  const [intensity, setIntensity] = useState(0)

  useEffect(() => {
    if (planetSelected) {
      let x = intensity
      let time = 200

      const startTimeout = () => {
        x += 0.01
        time -= 5
        setIntensity(x)
        if (time > 0) setTimeout(startTimeout, time)
      }

      startTimeout()
    }
  }, [planetSelected])

  return (
    <PointLight position={new Vector3(-45.5, 2, 7)} intensity={intensity} />
  )
}

export default PointLightAnim
