import React from "react"
import { Helmet } from "react-helmet"

interface Iseo {
  lang?: string
  description?: string
  meta?: string
  title?: string
  image?: string
  children?: React.ReactNode
}

const SEO: React.FC<Iseo> = ({ description, lang, meta, title, children, image }) => {
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={[
        {
          property: `og:locale`,
          content: lang
        },
        {
          property: `og:image`,
          content: image ? image : ''
        },
        {
          name: `description`,
          content: description ? description : '',
        },
        {
          property:`og:meta`,
          content: meta ? meta: '',
        },
        {
          property: `og:title`,
          content: title ? title : '',
        },
        {
          property: `og:description`,
          content: description ? description : '',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: description ? description : '',
        },
        {
          name: `twitter:title`,
          content: title ? title : '',
        },
        {
          name: `twitter:description`,
          content: description ? description : '',
        },
      ]}
    >{children}</Helmet>
  )
}

export default SEO
