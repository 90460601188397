import Box from "components/Box"
import Typography from "components/Typography"
import React, { useEffect } from "react"
import Lottie from "react-lottie-player"
import loader from "./Loader.json"
import { AnimatePresence, motion } from "framer-motion"
import { isMobile } from "utils"
import { NoiseOverlay } from "components/Noise"
import { navigate } from "gatsby"
import { getLangFromPath } from "../../utils/hooks"

const locales = ["PL", "EN"]

const MotionTypography = motion(Typography)
const MotionBox = motion(Box)

export const SplashScreen = ({ isLoaded, setSplash }) => {
  const lang = getLangFromPath();
  // useEffect(() => {
  //   const storedLanguage = localStorage.getItem("language")
  //   if (!locales.includes(storedLanguage))
  //     localStorage.setItem("language", "PL")
  // }, [])

  const onConfirmClick = () => {
    setSplash(false)
    navigate(`/${lang}/`)
  }

  return (
    <Box
      backgroundColor="black"
      position="absolute"
      width="100vw"
      height="100vh"
      zIndex="999"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <NoiseOverlay variant="light" />
      <AnimatePresence exitBeforeEnter>
        {!isLoaded && !isMobile && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="spinner"
          >
            <Lottie animationData={loader} loop play />
            <MotionTypography
              initial={{ opacity: 0.4 }}
              animate={{ opacity: 1 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 1,
              }}
              variant="loader"
              color="text.primary"
            >
              {getLangFromPath() === "pl" ? "ŁADOWANIE..." : "Loading..."}
            </MotionTypography>
          </motion.div>
        )}
        {(isLoaded || isMobile) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="menu"
            transition={{
              duration: 2,
            }}
            style={{ overflowY: "auto" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="menuTitle"
                  color="text.secondary"
                  fontSize={20}
                  letterSpacing={6.5}
                  lineHeight={1}
                  px={1}
                >
                  STANISŁAW
                </Typography>
                <Typography
                  variant="menuTitle"
                  color="text.secondary"
                  lineHeight={1}
                >
                  LEM
                </Typography>
              </Box>
              <MotionBox
                mt={5}
                maxWidth={450}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1, duration: 1 }}
                px={isMobile ? 5 : undefined}
              >
                {getLangFromPath() === "pl" ? (
                  <Typography variant="loader" color="text.primary">
                    Jeden z najbardziej niezwykłych i oryginalnych umysłów XX
                    wieku sam w sobie był istnym wszechświatem pojęć, koncepcji
                    i doświadczeń. Od 60 lat cząstka Lema – jego głos – dzięki
                    Polskiemu Radiu wędruje z prędkością światła przez odmęty
                    kosmosu. Wyobraź sobie, że wędrujesz razem z nim. Wybierz
                    się w niezwykłą podróż po Galaktyce Lema.
                    <br />
                    <br />
                    Przewodnikiem po tej wyprawie będzie głos Stanisława Lema,
                    dlatego, dla najpełniejszego doznania, zalecamy użycie
                    słuchawek.
                  </Typography>
                ) : (
                  <Typography variant="loader" color="text.primary">
                    Stanisław Lem, one of the most remarkable and original minds
                    of the twentieth century, who was himself a veritable
                    universe of ideas, concepts, and experiences. For the past
                    60 years, a part of Lem - his voice - thanks to Polish
                    Radio, has been traveling at the speed of light through the
                    depths of space. Imagine yourself accompanying Lem on this
                    journey, through the Lem Galaxy.
                    <br />
                    <br />
                    The guide for this trip will be the voice of Stanisław Lem,
                    so for the best experience, we recommend that you use
                    headphones.
                  </Typography>
                )}
              </MotionBox>
              <MotionBox
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2, duration: 1 }}
                backgroundColor="text.secondary"
                px={4}
                py={2}
                mt={5}
                style={{ cursor: "pointer" }}
                onClick={onConfirmClick}
              >
                <Typography variant="loader" color="black">
                  {getLangFromPath() === "pl" ? "WEJDŹ" : "ENTER"}
                </Typography>
              </MotionBox>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}

export default SplashScreen
