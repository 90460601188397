import { theme as themeConfig } from "UI/theme"
import React from "react"
import styled, { css } from "styled-components"
import {
  space,
  SpaceProps,
  variant,
  typography,
  TypographyProps,
  color,
  ColorProps,
  system,
} from "styled-system"

export type TypographyType = {
  variant?: keyof typeof themeConfig.textStyles
  children: React.ReactNode
  fluidFontSize?: number
} & SpaceProps &
  TypographyProps &
  ColorProps

export const Typography = styled.div<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  typography,
  space,
  color,
  () => css`
    & * {
      font-weight: inherit;
      font-family: inherit;
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      background-color: inherit;
      display: inherit;
      padding: inherit;
      padding-bottom: inherit;
      box-decoration-break: inherit;
    }
  `,
  system({
    color: {
      property: "color",
      scale: "palette",
    },
    backgroundColor: {
      property: "backgroundColor",
      scale: "palette",
    },
    fluidFontSize: {
      property: "fontSize",
      scale: "fluidFontSizes",
    },
  })
)
