import styled, { keyframes } from "styled-components"

const grainAnimation = keyframes`
  0%  { transform:translate3d(0, 0, 0) }
  10% { transform:translate3d(-5%, -10%, 0) }
  20% { transform:translate3d(-15%, 5%, 0) }
  30% { transform:translate3d(7%, -25%, 0) }
  40% { transform:translate3d(-5%, 25%, 0) }
  50% { transform:translate3d(-15%, 10%, 0) }
  60% { transform:translate3d(15%, 0%, 0) }
  70% { transform:translate3d(0%, 15%, 0) }
  80% { transform:translate3d(3%, 25%, 0) }
  90% { transform:translate3d(-10%, 10%, 0) }
  100% { transform:translate3d(0, 0, 0) }
`

export const Noise = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  &::after {
    content: "";
    background-image: url("/noise1.png");
    width: 400%;
    height: 400%;
    opacity: 0.3;
    position: absolute;
    animation: ${grainAnimation} 8s steps(10) infinite;
    animation-delay: 1s;
    /* will-change: transform; */
    left: -150%;
    top: -150%;
  }
`

export const NoiseGrey = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;

  &::after {
    content: "";
    background-image: url("/noise4.png");
    width: 200%;
    height: 200%;
    opacity: 0.06;
    position: absolute;
    animation: ${grainAnimation} 8s steps(7, end) infinite;
    pointer-events: none;

    animation-delay: 1s;
    /* will-change: transform; */
    left: -50%;
    top: -50%;
  }
  &:before {
    content: "";
    position: absolute;
    top: ${({ theme }) => theme.space[3]}px;
    left: ${({ theme }) => theme.space[3]}px;
    width: ${({ theme }) => `calc(100vw - 2 * ${theme.space[3]}px)`};
    height: ${({ theme }) => `calc(100vh - 2 * ${theme.space[3]}px)`};
    border: 1px solid #dad2d2;
    opacity: 0.3;
    z-index: 4;
  }
`

export const Tekstura = styled.div`
  content: "";
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/paper.jpg");
  opacity: 0.1;
`
