export const theme = {
  fontSizes: [12, 14, 16, 20, 24, 32],
  fluidFontSizes: ["0.5rem", "1rem", "1.5rem", "2rem", "2.5rem", "3rem"],
  space: [0, 4, 8, 16, 32, 64, 75, 128, 150],

  palette: {
    primary: {
      main: "#007CFB",
      dark: "#005EC0",
    },
    neutral: {
      dark: "#545D61",
      medium: "#BEC9D5",
      light: "#DDD4D5",
      veryLight: "#F1F5F9",
      ultraLight: "#F2EEED",
      white: "#fff",
    },
    accent: {
      blue: "#26B9E7",
      tiel: "#32D1CD",
      green: "#2EC17A",
      lightGreen: "#89E42D",
      yellow: "#F5D73F",
      orange: "#F5843F",
      red: "#df4a32",
      purple: "#B657EE",
    },
    text: {
      primary: "#dad2d2",
      secondary: "#df4a32",
      dark: "#23191c",
    },
    background: "#eaeff4",
  },
  textStyles: {
    menuTitle: {
      textShadow: "0 0 4px #df4a32",
      fontFamily: "Oswald",
      fontSize: 80,
      letterSpacing: "12.8px",
      fontWeight: 600,
    },
    menuItem: {
      textShadow: `0 0 3px rgba(255, 255, 255, 0.4)`,
      fontFamily: "Oswald",
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: "2.56px",
      textTransform: "uppercase",
      lineHeight: 3,
      "@media screen and (max-height: 850px)": {
        lineHeight: 2,
      },
    },
    mobilePlanetLabel: {
      textShadow: `0 0 3px rgba(255, 255, 255, 0.4)`,
      fontFamily: "Oswald",
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: "2.56px",
      textTransform: "uppercase",
      lineHeight: 1,
    },
    chapterTitle: {
      textShadow: "0 0 7px rgba(223, 74, 50, 0.49)",
      fontFamily: "Oswald",
      fontSize: 32,
      letterSpacing: "2.56px",
      fontWeight: 500,
      textTransform: "uppercase",
    },
    loader: {
      fontFamily: "Oswald",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 1.28,
      textAlign: "center",
    },
    modalTitle: {
      fontFamily: "Oswald",
      fontSize: 32,
      letterSpacing: "2.56px",
      fontWeight: 500,
      textTransform: "uppercase",
    },
    chapterIndex: {
      fontFamily: "Oswald",
      fontSize: 14,
      letterSpacing: "1.12px",
      textTransform: "uppercase",
      fontWeight: 500,
    },
    ribbonTitle: {
      fontFamily: "Oswald",
      fontSize: 16,
      letterSpacing: "1.6px",
      textTransform: "uppercase",
    },
    audioTitle: {
      fontFamily: "Oswald",
      fontSize: 16,
      letterSpacing: "1.28px",
      fontWeight: 400,
      lineHeight: "26px",
    },
    button: {
      fontFamily: "Oswald",
      fontSize: 20,
      letterSpacing: "2px",
      fontWeight: 400,
      lineHeight: "45px",
      textTransform: "uppercase",
    },
    langButton: {
      fontFamily: "Oswald",
      fontSize: 14,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    body2: {
      fontSize: 12,
      lineHeight: "16px",
    },
    caption: {
      fontSize: 14,
      lineHeight: "20px",
      letterSpacing: "0.91px",
      opacity: 0.7,
      marginTop: 19,
    },
    body: {
      fontSize: 16,
      lineHeight: "30px",
    },
    bodyModal: {
      fontSize: 16,
      lineHeight: "32px",
      letterSpacing: "1.04px",
    },
    bodySection: {
      fontSize: 16,
      lineHeight: "30px",
      letterSpacing: "1.04px",
      fontWeight: 500,
    },
    lead: {
      fontSize: 20,
      lineHeight: "34px",
      letterSpacing: "1.3",
      fontWeight: 700,
    },
    questionsName: {
      fontFamily: "Oswald",
      fontWeight: 500,
      letterSpacing: "2.34px",
      textTransform: "uppercase",
      lineHeight: 1,
    },
    name: {
      fontFamily: "Oswald",
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: "2.4px",
    },
    lastName: {
      fontFamily: "Oswald",
      fontSize: 32,
      lineHeight: "45px",
      fontWeight: 600,
      letterSpacing: "3.2px",
      textTransform: "uppercase",
    },
    h3: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
    },
    h2: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 700,
    },
    h1: {
      fontSize: 20,
      lineHeight: "28px",
      fontWeight: 700,
    },
  },
}
