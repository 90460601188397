import styled from "styled-components"
import { motion } from "framer-motion"

export const MenuList = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const MenuItem = styled(motion.li)`
  position: relative;
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.space[1]}px;

  span {
    display: inline;
    padding-right: ${({ theme }) => theme.space[2]}px;
    cursor: pointer;
    pointer-events: all;
    z-index: 2;
    position: relative;
    &:hover ~ div {
      transform: translate3d(0%, 0, 0);
    }
  }
`

export const BackgroundBlock = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.palette.accent.red};
  height: 100%;
  width: 1000%;
  right: 0;
  top: 0;
  z-index: 1;
  transform: ${({ isVisible }) =>
    isVisible ? "translate3d(0%, 0, 0)" : "translate3d(-40%, 0, 0)"};
  transition: 0.3s ease-in-out;
`
