import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  LayoutProps,
  layout,
  color,
  ColorProps,
  position,
  PositionProps,
  border,
  BorderProps,
  system,
} from "styled-system"

export interface IBox
  extends ColorProps,
    PositionProps,
    LayoutProps,
    BorderProps,
    SpaceProps,
    FlexboxProps {}

export const Box = styled.div<IBox>(
  space,
  flexbox,
  layout,
  color,
  position,
  border,
  system({
    backgroundColor: {
      property: "backgroundColor",
      scale: "palette",
    },
  })
)
